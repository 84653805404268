/* import __COLOCATED_TEMPLATE__ from './edit-category-security.hbs'; */
import { not } from "@ember/object/computed";
import { buildCategoryPanel } from "discourse/components/edit-category-panel";
import PermissionType from "discourse/models/permission-type";
import discourseComputed from "discourse-common/utils/decorators";

export default buildCategoryPanel("security", {
  selectedGroup: null,
  noGroupSelected: not("selectedGroup"),

  @discourseComputed("category.permissions.@each.permission_type")
  everyonePermission(permissions) {
    return permissions.findBy("group_name", "everyone");
  },

  @discourseComputed("category.permissions.@each.permission_type")
  everyoneGrantedFull() {
    return (
      this.everyonePermission &&
      this.everyonePermission.permission_type === PermissionType.FULL
    );
  },

  @discourseComputed("everyonePermission")
  minimumPermission(everyonePermission) {
    return everyonePermission
      ? everyonePermission.permission_type
      : PermissionType.READONLY;
  },

  actions: {
    onSelectGroup(group_name) {
      this.category.addPermission({
        group_name,
        permission_type: this.minimumPermission,
      });
    },

    onChangeEveryonePermission(everyonePermissionType) {
      this.category.permissions.forEach((permission, idx) => {
        if (permission.group_name === "everyone") {
          return;
        }

        if (everyonePermissionType < permission.permission_type) {
          this.category.set(
            `permissions.${idx}.permission_type`,
            everyonePermissionType
          );
        }
      });
    },
  },
});
